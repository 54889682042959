<template>
  <div class="commission-profiles-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <a-icon :type="isEdit ? 'edit' : 'form'" class="form-icon" />
                <span>{{
                  isEdit
                    ? `${labels.form.titleView} número: ${form.code || ""}`
                    : labels.form.titleCreate
                }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :gutter="16"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
              >
                <a-col>
                  <a-button-group>
                    <a-button
                      :type="isEdit ? 'primary' : 'danger'"
                      ghost
                      @click="handleCancel"
                      icon="arrow-left"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <a-button
                      v-if="isEdit && canDelete"
                      type="danger"
                      @click="confirmDelete"
                    >
                      <span>{{ labels.form.deleteCommissionProfile }}</span>
                    </a-button>
                    <a-button
                      v-if="!isMobile && canEdit"
                      type="primary"
                      ghost
                      :loading="submitting"
                      @click="validate().then(() => handleSubmit(true))"
                    >
                      <span>{{ labels.form.saveAndClose }}</span>
                    </a-button>
                    <a-button
                      v-if="canEdit"
                      type="primary"
                      :loading="submitting"
                      @click="validate().then(handleSubmit)"
                    >
                      <span>{{ labels.form.save }}</span>
                    </a-button>
                    <a-dropdown :trigger="['click']" v-if="isMobile && canEdit">
                      <a-menu slot="overlay">
                        <a-menu-item
                          key="1"
                          @click="validate().then(() => handleSubmit(true))"
                          >{{ labels.form.saveAndClose }}</a-menu-item
                        >
                      </a-menu>
                      <a-button type="primary" ghost icon="more" />
                    </a-dropdown>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="center">
            <a-col :span="24">
              <a-card>
                <a-row type="flex" justify="space-between">
                  <a-col :xs="20" :sm="20" :md="8">
                    <Input
                      v-model="form.name"
                      :vid="labels.form.name.label"
                      :label="labels.form.name.label"
                      :name="labels.form.name.label"
                      :formitem="{ label: labels.form.name.label }"
                      :placeholder="labels.form.name.placeholder"
                      rules="required"
                      :readOnly="!canEdit"
                      :allowClear="true"
                    />
                  </a-col>
                  <a-col :xs="20" :sm="20" :md="8">
                    <SelectPagination
                      v-model="form.profile"
                      :fetchService="fetchCommissionProfiles"
                      :placeholder="labels.form.profile.placeholder"
                      :labelKey="'name'"
                      :valueKey="'id'"
                      :label="labels.form.profile.label"
                      :disabled="!canEdit"
                      :allowClear="true"
                    />
                  </a-col>
                  <a-col>
                    <SwitchComponent
                      v-model="form.status"
                      :vid="labels.form.status.label"
                      :showLabel="true"
                      :label="labels.form.status.label"
                      :name="labels.form.status.label"
                      :formitem="{ label: labels.form.status.label }"
                      :disabled="!canEdit"
                    />
                  </a-col>
                </a-row>
                <!-- DIVIDER -->
                <a-row type="flex" justify="start">
                  <a-col :span="24">
                    <a-divider orientation="left">{{
                      labels.form.type.label
                    }}</a-divider>
                  </a-col>
                </a-row>
                <!-- REFERRAL COMMISSION -->
                <a-row
                  type="flex"
                  :justify="isMobile ? 'space-between' : 'start'"
                >
                  <a-col :xs="16" :sm="16" :md="6">
                    <h3 class="type-label">
                      {{ labels.form.type.referralCommission.label }}
                    </h3>
                  </a-col>
                  <a-col v-bind="isMobile ? {} : { sm: 24, md: 8 }">
                    <SwitchComponent
                      v-model="form.schema[0].status"
                      :vid="labels.form.type.referralCommission.label"
                      :showLabel="false"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="[24, 24]">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[0].rule_minimum_amount"
                      type="number"
                      :vid="
                        labels.form.type.referralCommission.ruleMinimumAmount
                          .label
                      "
                      :label="
                        labels.form.type.referralCommission.ruleMinimumAmount
                          .label
                      "
                      :name="
                        labels.form.type.referralCommission.ruleMinimumAmount
                          .label
                      "
                      :placeholder="
                        labels.form.type.referralCommission.ruleMinimumAmount
                          .placeholder
                      "
                      :min="0"
                      :disabled="!canEdit || !form.schema[0].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[0].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[0].amount"
                      type="number"
                      :vid="labels.form.type.referralCommission.amount.label"
                      :label="labels.form.type.referralCommission.amount.label"
                      :name="labels.form.type.referralCommission.amount.label"
                      :placeholder="
                        labels.form.type.referralCommission.amount.placeholder
                      "
                      :min="0"
                      :disabled="!canEdit || !form.schema[0].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[0].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                </a-row>

                <!-- AFFILIATED NETWORK COMMISSION -->
                <a-row
                  type="flex"
                  :justify="isMobile ? 'space-between' : 'start'"
                >
                  <a-col :xs="16" :sm="16" :md="6">
                    <h3 class="type-label">
                      {{ labels.form.type.affiliateNetworkCommission.label }}
                    </h3>
                  </a-col>
                  <a-col v-bind="isMobile ? {} : { sm: 24, md: 8 }">
                    <SwitchComponent
                      v-model="form.schema[1].status"
                      :vid="labels.form.type.affiliateNetworkCommission.label"
                      :showLabel="false"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="[24, 24]">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[1].level1_percentage"
                      type="number"
                      :vid="
                        labels.form.type.affiliateNetworkCommission
                          .level1Percentage.label
                      "
                      :label="
                        labels.form.type.affiliateNetworkCommission
                          .level1Percentage.label
                      "
                      :name="
                        labels.form.type.affiliateNetworkCommission
                          .level1Percentage.label
                      "
                      :placeholder="
                        labels.form.type.affiliateNetworkCommission
                          .level1Percentage.placeholder
                      "
                      :min="0"
                      :max="100"
                      :disabled="!canEdit || !form.schema[1].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[1].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[1].level2_percentage"
                      type="number"
                      :vid="
                        labels.form.type.affiliateNetworkCommission
                          .level2Percentage.label
                      "
                      :label="
                        labels.form.type.affiliateNetworkCommission
                          .level2Percentage.label
                      "
                      :name="
                        labels.form.type.affiliateNetworkCommission
                          .level2Percentage.label
                      "
                      :placeholder="
                        labels.form.type.affiliateNetworkCommission
                          .level2Percentage.placeholder
                      "
                      :min="0"
                      :max="100"
                      :disabled="!canEdit || !form.schema[1].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[1].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                </a-row>

                <!-- USER DEPOSIT COMMISSION -->
                <a-row
                  type="flex"
                  :justify="isMobile ? 'space-between' : 'start'"
                >
                  <a-col :xs="16" :sm="16" :md="6">
                    <h3 class="type-label">
                      {{ labels.form.type.userDepositCommission.label }}
                    </h3>
                  </a-col>
                  <a-col v-bind="isMobile ? {} : { sm: 24, md: 8 }">
                    <switch-component
                      v-model="form.schema[2].status"
                      :vid="labels.form.type.userDepositCommission.label"
                      :showLabel="false"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="[24, 24]">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[2].rule_minimum_amount"
                      type="number"
                      :vid="
                        labels.form.type.userDepositCommission.ruleMinimumAmount
                          .label
                      "
                      :label="
                        labels.form.type.userDepositCommission.ruleMinimumAmount
                          .label
                      "
                      :name="
                        labels.form.type.userDepositCommission.ruleMinimumAmount
                          .label
                      "
                      :placeholder="
                        labels.form.type.userDepositCommission.ruleMinimumAmount
                          .placeholder
                      "
                      :min="0"
                      :disabled="!canEdit || !form.schema[2].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[2].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[2].percentage"
                      type="number"
                      :vid="
                        labels.form.type.userDepositCommission.percentage.label
                      "
                      :label="
                        labels.form.type.userDepositCommission.percentage.label
                      "
                      :name="
                        labels.form.type.userDepositCommission.percentage.label
                      "
                      :placeholder="
                        labels.form.type.userDepositCommission.percentage
                          .placeholder
                      "
                      :min="0"
                      :max="100"
                      :disabled="!canEdit || !form.schema[2].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[2].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                </a-row>

                <!-- REGISTRATION BONUS -->
                <a-row
                  type="flex"
                  :justify="isMobile ? 'space-between' : 'start'"
                >
                  <a-col :xs="16" :sm="16" :md="6">
                    <h3 class="type-label">
                      {{ labels.form.type.userRegistrationBonus.label }}
                    </h3>
                  </a-col>
                  <a-col v-bind="isMobile ? {} : { sm: 24, md: 8 }">
                    <SwitchComponent
                      v-model="form.schema[3].status"
                      :vid="labels.form.type.userRegistrationBonus.label"
                      :showLabel="false"
                    />
                  </a-col>
                </a-row>
                <a-row type="flex" justify="start" :gutter="[24, 24]">
                  <a-col :xs="24" :sm="24" :md="12">
                    <Input
                      v-model="form.schema[3].amount"
                      type="number"
                      :vid="labels.form.type.userRegistrationBonus.amount.label"
                      :label="
                        labels.form.type.userRegistrationBonus.amount.label
                      "
                      :name="
                        labels.form.type.userRegistrationBonus.amount.label
                      "
                      :placeholder="
                        labels.form.type.userRegistrationBonus.amount
                          .placeholder
                      "
                      :min="0"
                      :disabled="!canEdit || !form.schema[3].status"
                      :allowClear="true"
                      v-bind="
                        form.schema[0].status ? { rules: 'required' } : {}
                      "
                    />
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import Input from "@/components/core/VeeValidateForm/Input.vue";
import SwitchComponent from "@/components/core/VeeValidateForm/Switch.vue";
import SelectPagination from "@/components/core/SelectPagination/index.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import router from "@/router";
import * as actions from "@/utils/actions";
import { mask } from "vue-the-mask";
import { adminPageTitle, hasRoles, roles } from "../../../utils/utils";

const initialFormState = {
  name: "",
  status: true,
  schema: [
    {
      type: "REFERRAL_COMMISSION",
      rule_minimum_amount: 0,
      amount: 0,
      status: false,
    },
    {
      type: "AFFILIATE_NETWORK_COMMISSION",
      level1_percentage: 0,
      level2_percentage: 0,
      status: false,
    },
    {
      type: "USER_DEPOSIT_COMMISSION",
      rule_minimum_amount: 0,
      percentage: 0,
    },
    {
      type: "USER_REGISTRATION_BONUS",
      amount: 0,
      status: false,
    },
  ],
};

export default {
  name: "CommissionProfileForm",
  directives: { themask: mask },
  components: {
    ValidationObserver,
    Input,
    SwitchComponent,
    SelectPagination,
  },
  metaInfo: {
    title: adminPageTitle(labels.commissionProfiles.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("view")
          ? labels.commissionProfiles.pageTitleViewing
          : labels.commissionProfiles.pageTitleCreate
      }`;
    },
  },
  data() {
    return {
      labels: labels.commissionProfiles,
      loading: false,
      submitting: false,
      form: _.cloneDeep(initialFormState),
    };
  },
  async created() {
    this.resetForm();
    if (this.$route.params.id) {
      this.fetchData();
    }
  },
  computed: {
    ...mapState("commissionProfiles", ["commissionProfile"]),
    ...mapState("layout", ["screenWidth"]),
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    isEdit() {
      return !!this.$route.params?.id;
    },
    canEdit() {
      const { accessRoles } = this.getLoggedUser;
      return (
        !this.isEdit ||
        hasRoles(accessRoles, [
          roles.admin.ROLE_USER_COMMISSION_PROFILES_UPDATE,
        ])
      );
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [
        roles.admin.ROLE_USER_COMMISSION_PROFILES_DELETE,
      ]);
    },
    dateFormat() {
      return "DD/MM/YYYY hh:mm:ss";
    },
    serverDateFormat() {
      return "YYYY-MM-DD HH:mm:ss";
    },
    money() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
      };
    },
    isMobile() {
      return this.screenWidth < 768;
    },
  },
  methods: {
    ...mapActions("commissionProfiles", [
      "fetchCommissionProfile",
      "createCommissionProfile",
      "updateCommissionProfile",
      "deleteCommissionProfile",
      "resetForm",
      "fetchCommissionProfiles",
    ]),
    async fetchData() {
      try {
        this.loading = true;
        await this.fetchCommissionProfile(this.$route.params.id);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
        this.$router.push({ name: "admin.commission-profiles" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let editting = this.isEdit;
      try {
        const formData = _.cloneDeep(this.form);
        formData.schema = formData.schema.filter((item) => item.status);

        response = editting
          ? await this.updateCommissionProfile(formData)
          : await this.createCommissionProfile(formData);

        if (response) this.$message.success(response.message);
        if (closeAfterSave) {
          this.$router.push({ name: "admin.commission-profiles" });
        } else {
          if (!editting) {
            this.$router.push({
              name: "admin.commission-profiles.edit",
              params: { id: response.data.id },
            });
          }
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
      } finally {
        this.submitting = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "admin.commission-profiles" });
    },
    confirmDelete() {
      this.$confirm({
        title: this.labels.confirm.title,
        content: this.labels.confirm.subtitle,
        okText: this.labels.confirm.confirmButtonText,
        okType: "danger",
        icon: "close-circle",
        cancelText: this.labels.confirm.cancelButtonText,
        onOk: async () => {
          try {
            await this.deleteCommissionProfile(this.form.id);
            this.$store.commit(
              `commissionProfiles/${actions.GET_COMMISSION_PROFILE}`,
              null
            );
            this.$message.success(this.labels.deletedSuccess);
            this.$router.push({ name: "admin.commission-profiles" });
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message ||
                this.labels.deletedError
            );
          }
        },
      });
    },
  },
  watch: {
    "$route.params.id"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
    commissionProfile: {
      handler(commissionProfile) {
        Object.assign(
          this.form,
          commissionProfile
            ? {
                ..._.cloneDeep(commissionProfile),
                schema: _.cloneDeep(initialFormState.schema).map((item) => {
                  const schema = commissionProfile?.schema.find(
                    (schema) => schema.type === item.type
                  );
                  return schema ? schema : item;
                }),
              }
            : _.cloneDeep(initialFormState)
        );
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.form = _.cloneDeep(initialFormState);
    this.resetForm();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.commission-profiles-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .type-label {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
</style>
